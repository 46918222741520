<template>
  <fragment>
    <div class="pa-4">
      <p class="mb-4">
        Your device has been succesfully added.
      </p>
      <p class="mb-4">
        Now you can take advantage of all the tools made available by ShellHub, viewing the
        registered devices, the sessions in each of them and getting access through the terminal
        interface.
      </p>
      <p class="mb-2">
        For more details, consult our <a
          target="_blank"
          href="http://docs.shellhub.io/"
        >
          documentation
        </a>
        and, if you have any questions, contact us through the
        <a
          target="_blank"
          href="https://gitter.im/shellhub-io/community"
        >
          Gitter
        </a>.
      </p>
      <p>
        Thank you for choosing Shellhub.
      </p>
    </div>
  </fragment>
</template>

<script>

export default {
  name: 'WelcomeFourthScreenComponent',
};

</script>
