<template>
  <fragment>
    <router-view />
  </fragment>
</template>

<script>

export default {
  name: 'SimpleLayoutComponent',
};

</script>
